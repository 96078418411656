import React from "react"

const ServicesBlock = () => (
  <>
  <section className="services">
    <h2 className="section-title anchor" id="services">Services</h2>
    <h3 className="section-subtitle">Identifying club weak points</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
            <thead>
              <tr>
                <th></th>
                <th>Base</th>
                <th>Pro</th>
                <th>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Squad analysis</td>
                <td>Quick squad analysis</td>
                <td colSpan="2">Comprehensive squad analysis, analysis of player relevance for the market and injury proneness</td>
              </tr>
              <tr>
                <td>Weak points</td>
                <td>
                  Identifying two weak points;<br />
                  Comparison with one player from the agent portfolio
                </td>
                <td>
                  Identifying two weak points;<br />
                  Comparison with all relevant player from the agent portfolio
                </td>
                <td>
                  Identifying a model player that would make the team strong in its weak points;<br />
                  Selection of players for the team's weak points
                </td>
              </tr>


              <tr className="bool-param">
                <td>Analysis of tactics and statistical data for the last season</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Analysis of the typology of the created and conceded moments</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Distribution of minutes played per position</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Video analysis of the team play (build-up, midfield trends, finishing)</td>
                <td></td>
                <td></td>
                <td><span className="yes"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h3 className="section-subtitle">Player overview</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
              <thead>
                <tr>
                  <th></th>
                  <th>Base</th>
                  <th>Premium</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bool-param">
                  <td>Basic player statistics, his career history</td>
                  <td><span className="yes"></span></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Selection of match reports for last season</td>
                  <td><span className="yes"></span></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Subscribe to match reports for the season on the selected parameters</td>
                  <td></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr className="bool-param">
                  <td>Identifying player's strengths and a video overview on him</td>
                  <td></td>
                  <td><span className="yes"></span></td>
                </tr>
                <tr>
                  <td>Player level in relation to his league</td>
                  <td>Basic comparison</td>
                  <td>Comparison by player's strengths</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
    <h3 className="section-subtitle">Transfer recommendation</h3>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <table className="services-comparison wow fadeIn">
            <thead>
              <tr>
                <th></th>
                <th>Analysis for any league</th>
                <th>Analysis for a macroregion<br />(Western/Eastern Europe)</th>
                <th>Analysis for Asian market</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bool-param">
                <td>Detailed player description, statistics, history, career and strengths / weaknesses</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Style analysis of the teams (tactics, tempo, philosophy)</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Selection of 3 teams most suitable for the player + detailed analysis and identification of advantages in comparison with competitors from these teams</td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>A selection of 20 clubs suitable for the player (cost, salary, transfer history)</td>
                <td></td>
                <td><span className="yes"></span></td>
                <td><span className="yes"></span></td>
              </tr>
              <tr className="bool-param">
                <td>Comparing the player with all the players of the league in his position</td>
                <td><span className="yes"></span></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Estimation of transfer probability</td>
                <td>To every club of the league</td>
                <td colSpan="2">To every of 20 best matches</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>

  <section className="services services-mobile">
    <h2 className="section-title anchor" id="services-mobile">Services</h2>
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <h3 className="services-mobile-subtitle">Identifying club weak points</h3>
          <h4 className="services-mobile-tariff">Base</h4>
          <ul className="services-mobile-list">
            <li>Quick squad analysis</li>
            <li>Identifying two weak points</li>
            <li>Comparison with one player from the agent portfolio</li>
            <li>Analysis of tactics and statistical data for the last season</li>
          </ul>
          <h4 className="services-mobile-tariff">Pro</h4>
          <ul className="services-mobile-list">
            <li>Comprehensive squad analysis, analysis of player relevance for the market and injury proneness</li>
            <li>Identifying two weak points</li>
            <li>Comparison with all relevant player from the agent portfolio</li>
            <li>Analysis of tactics and statistical data for the last season</li>
            <li>Analysis of the typology of the created and conceded moments</li>
            <li>Distribution of minutes played per position</li>
          </ul>
          <h4 className="services-mobile-tariff">Premium</h4>
          <ul className="services-mobile-list">
            <li>Everything included in the <strong>Pro</strong> package</li>
            <li>Video analysis of the team play (build-up, midfield trends, finishing)</li>
          </ul>
          <h3 className="services-mobile-subtitle">Player overview</h3>
          <h4 className="services-mobile-tariff">Base</h4>
          <ul className="services-mobile-list">
            <li>Basic player statistics, his career history</li>
            <li>Selection of match reports for last season</li>
            <li>Basic comparison of the player level in relation to his league</li>
          </ul>
          <h4 className="services-mobile-tariff">Premium</h4>
          <ul className="services-mobile-list">
            <li>Basic player statistics, his career history</li>
            <li>Selection of match reports for last season</li>
            <li>Subscribe to match reports for the season on the selected parameters</li>
            <li>Identifying player's strengths and a video overview on him</li>
            <li>Player level in relation to his league in relation to his strengths</li>
          </ul>
          <h3 className="services-mobile-subtitle">Transfer recommendation</h3>
          <ul className="services-mobile-list">
            <li>Detailed player description, statistics, history, career and strengths / weaknesses</li>
            <li>Style analysis of the teams (tactics, tempo, philosophy)</li>
            <li>Selection of 3 teams most suitable for the player + detailed analysis and identification of advantages in comparison with competitors from these teams</li>
          </ul>
          <h4 className="services-mobile-tariff">Analysis for any league</h4>
          <ul className="services-mobile-list">
            <li>Comparing the player with all the players of the league in his position</li>
            <li>Estimation of transfer probability to every club in the league</li>
          </ul>
          <h4 className="services-mobile-tariff">Analysis for a macroregion (Western/Eastern Europe) or the Asian market</h4>
          <ul className="services-mobile-list">
            <li>A selection of 20 clubs suitable for the player (cost, salary, transfer history)</li>
            <li>Estimation of transfer probability to each of the 20 selected clubs</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  </>
)

export default ServicesBlock
