import React from "react"

const FeaturesBlock = () => (
  <section className="features">
    <div className="container">
      <div className="row">
        <div className="feature col-md-4 col-12 wow fadeInDown">
          <div className="feature-icon help"></div>
          <p>We take players under our care and help them in all their legal matters. Whatever happens, we are on the player's side, always supporting his position and achieving the desired outcome. We represent the interests of players in FIFA and CAS.
          </p>
        </div>
        <div className="feature col-md-4 col-12 wow fadeInDown" style={ { animationDelay: '0.3s' } }>
          <div className="feature-icon search"></div>
          <p>If a player has decided to change his club, we are doing everything to make this happen. We promote the player and find suitable options in the international market, draw up a contract on favorable terms and accompany his signing legally.</p>
        </div>
        <div className="feature col-md-4 col-12 wow fadeInDown" style={ { animationDelay: '0.6s' } }>
          <div className="feature-icon docs"></div>
          <p>We are very strong in sports law. We fully control the paperwork of all our players, and if it comes to court, we win most of our cases.</p>
        </div>
      </div>
    </div>
  </section>
)

export default FeaturesBlock