import React from "react"

import Logo from "../../images/logo-white.inline.svg";

const HeroBlock = () => (
  <section className="welcome-screen">
    <div className="half company">
      <Logo className="logo" />
    </div>
    <div className="half about">
      <div className="text">
        <p>An agency that places the player's interests above everything else.</p>
        <p>We work with each of our players in an individual manner. Our players are not just clients, they are friends who trust us with their business. We are proud of our legal skills, doing all the paperwork for the player so that he can focus on playing football.</p>
        <p>We have extensive experience in the international transfer market. We find the best options for our players and fully accompany them before signing a contract with a new club.</p>
      </div>
    </div>
    <div className="scroll-down"></div>
  </section>
)

export default HeroBlock