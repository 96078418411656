import React from "react"

export default class PortfolioBlock extends React.Component {

  renderTransfer(transfer) {
    return (
      <div key={ `${transfer.player} ${transfer.from}` } className="portfolio-item col-lg-4 col-md-6 col-12 wow fadeIn">
        <div className="images">
          <img className="team-logo" src={ transfer.from_logo.childImageSharp.fixed.src } alt={ transfer.from } />
          <img className="player-photo" src={ transfer.image.childImageSharp.fixed.src } alt={ transfer.player } />
          <img className="team-logo" src={ transfer.to_logo.childImageSharp.fixed.src } alt={ transfer.to } />
        </div>
        <h4 className="player-name">{ transfer.player }</h4>
        <p className="transfer-meta">from <strong>{ transfer.from }</strong> to <strong>{ transfer.to }</strong></p>
      </div>
    )
  }

  renderDoc = (doc) => {
    return (
      <a key={ doc.pdf } href={ doc.pdf } target="_blank" rel="noopener noreferrer" className="portfolio-item col-lg-4 col-md-6 col-12 wow fadeIn">
        <div className="images">
        </div>
        <h4 className="player-name">{ doc.player }</h4>
        <p className="transfer-meta"><strong>{ doc.date }</strong></p>
      </a>
    )
  }

  render() {
   return (
    <section className="portfolio">
      <h2 className="section-title anchor" id="transfers">Transfers</h2>
      <h3 className="section-subtitle">Actions speak louder than words</h3>
      <div className="container">
        <div className="row">
          { this.props.transfers.map(transfer => this.renderTransfer(transfer)) }
        </div>
      </div>
      <h2 className="section-title anchor" id="law">Sports law</h2>

      <h3 className="section-subtitle">Player/Club Contracts and Transfers</h3>

      <div className="container">
        <p>Catenaccio advises on player/club contract issues. We represent clubs and players. We advise on issues in relation to:</p>
        <ul>
        <li>All aspects of player contracts and associated documentation;</li>
        <li>Standard playing contracts and team member agreements for leagues, teams and governing bodies;</li>
        <li>Transfer arrangements both at a domestic and international level, including drafting transfer and loan documentation;</li>
        <li>The negotiation and drafting of player contracts, including bonus structures and “buy-out” clauses;</li>
        <li>Pre-contract and option arrangements;</li>
        <li>Image Rights</li>
        <li>International and domestic approach rules and general regulatory compliance;</li>
        <li>Article 17 cases under the FIFA Regulations;</li>
        <li>Third Party Ownership issues and compliance;</li>
        <li>Training Compensation and Solidarity cases;</li>
        <li>Issues under the Intermediary Regulations including the content of representation contracts, permissible remuneration structures and general compliance issues;</li>
        <li>Compensation for out of contract players in England including representation before the Professional Football Compensation Committee;</li>
        <li>The international Movement of Minors under the FIFA Regulations; and</li> 
        <li>Managerial and ‘backroom’ staff appointments including negotiating and drafting service agreements, compromise agreements and disputes before the Managers’ Arbitration Tribunal.</li>
        </ul>

      </div>

      <h3 className="section-subtitle">Dispute Resolution</h3>
      <div className="container">
        <p>Catenaccio advises clients on disputes and their resolution, including before the following forums:</p>
        <ul>
        <li>Sports’ governing body dispute resolution mechanisms including The FA, the Premier League, the National Football Leagues worldwide, UEFA, FIFA.</li>
        <li>The Court of Arbitration for Sport;</li>
        <li>Sport Resolutions’ arbitration and mediation forums; and</li>
        <li>The civil and criminal courts.</li>
        </ul>
        <p>Catenaccio advises on all types of disciplinary matters, including:</p>
        <ul>
        <li>Disciplinary procedures and processes; and</li>
        <li>Representation before disciplinary panels, hearings and appeal hearings.</li>
        </ul>
      </div>

      <h3 className="section-subtitle">Individual Representation</h3>
      <div className="container">
      <p>Catenaccio has extensive experience of advising individuals working in sport, the players and their agents/intermediaries in relation to:</p>
          <ul>
          <li>Playing contracts;</li>
          <li>Service Agreements;</li>
          <li>Image Rights</li>
          <li>Defamation; and</li>
          <li>Media & Crisis management.</li>
          </ul>
      </div>

      
      <div className="container">
        <div className="row">
          { this.props.law.map(doc => this.renderDoc(doc)) }
        </div>
      </div>
    </section>
  )
  }
}
