import React from "react"
import { Link } from "gatsby"

export default class NewsBlock extends React.Component {

  renderNews = (news) => {
    if (!news.title_en) return null;
    return (
      <div className="news-article col-lg-6 col-12 wow fadeInUp">
        <Link to={ `en/news/${news.slug}` } className="news-content">
          <div className="news-image-wrapper">
          <img src={ news.image.childImageSharp.fluid.src } />
          </div>
          <div className="news-article-info">
            <h3 className="news-article-title">{ news.title_en }</h3>
            <p className="news-article-subtitle">{ news.subtitle_en || news.date_en }</p>
          </div>
        </Link>
      </div>
    )
  }

  render() {
    return (
      <section className="news">
        <h2 className="section-title anchor" id="news">News</h2>
        <div className="container">
          <div className="row">
            { this.props.news.map(news => this.renderNews(news)) }
          </div>
        </div>
      </section>
    )
  }
}